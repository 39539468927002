import React, {useContext, useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import CustomTable from '../../components/CustomTable/CustomTable';
import {
  createSchoolAdmin,
  fetchSchoolList,
  fetchSchoolAdmins,
  updateSchoolAdminData,
} from '../../store/actions/schoolAdminsActions';
import {checkIsSuperAdmin} from '../../utilityFunction/helper';
import {useNavigate} from '../../routes/index.js';
import useUserRole from '../../hooks/useUserRole';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';
import SchoolAdminFormDialog from './SchoolAdminFormDialog.js';
import {
  addSchoolAdminInitialValues,
  validationSchema,
} from '../../validationSchema/SchoolAdminSchema.js';
import {useFormik} from 'formik';
import {PiDotsThreeVertical} from 'react-icons/pi';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';

export default function SchoolAdminsListing() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {isAuthenticated} = useSelector(state => state?.auth);

  const userRole = useUserRole();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedSchoolAdmin, setSelectedSchoolAdmin] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const schoolAdminsList = useSelector(
    state => state?.schoolAdmins?.schoolAdmins?.data?.data,
  );
  const schoolAdminLastPage = useSelector(
    state => state?.schoolAdmins?.schoolAdmins?.data?.last_page,
  );
  const schoolAdminTotalCount = useSelector(
    state => state?.schoolAdmins?.schoolAdmins?.data?.total,
  );
  const schoolList = useSelector(state => state?.schoolAdmins?.listOfSchools);
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);

  // Ref for dropdown menu
  const dropdownRef = useRef(null);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setIsDropdownOpen(false);
    setEditMode(false);
    setSelectedSchoolAdmin(null);
    resetForm();
  };

  const onSortChange = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);

    const sortedStudentData = [...studentData].sort((a, b) => {
      if (sortOrder === 'asc') {
        if (a.first_name < b.first_name) return -1;
        if (a.first_name > b.first_name) return 1;
      } else {
        if (a.first_name > b.first_name) return -1;
        if (a.first_name < b.first_name) return 1;
      }
      return 0;
    });

    setStudentData(sortedStudentData);
  };

  const onPageChange = page => {
    setCurrentPage(page);
  };

  const onSearch = e => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);

    const filteredStudentData =
      e.target.value === ''
        ? studentList
        : studentData?.filter(student => {
            const fullName =
              `${student.first_name} ${student.last_name}`.toLowerCase();
            return fullName.includes(e.target.value.toLowerCase());
          });

    setStudentData(filteredStudentData);
  };

  const onTabChange = selectTabId => {
    setSelectedRole(selectTabId);
    setCurrentPage(1);
  };

  const onSelectChange = value => {
    setSchoolId(value);
  };

  const isSuperAdminRole = checkIsSuperAdmin(userRole);

  const fetchSchoolAdminsData = async () => {
    await dispatch(fetchSchoolAdmins({currentPage, loaderDispatch}));
  };

  const fetchSchoolListData = async () => {
    await dispatch(fetchSchoolList());
  };

  const handleItemSelect = schoolAdmin => {
    if (selectedSchoolAdmin?.id === schoolAdmin?.id)
      setSelectedSchoolAdmin(null);
    else setSelectedSchoolAdmin(schoolAdmin);
    setIsDropdownOpen(true);
  };

  const editDetails = schoolAdmin => {
    setEditMode(true);
    setOpenDialog(true);
  };

  const handleShowForm = () => {
    setOpenDialog(true);
  };

  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: addSchoolAdminInitialValues,
    onSubmit: values => {
      if (!editMode) {
        dispatch(
          createSchoolAdmin({
            payload: values,
            intl,
            loaderDispatch,
          }),
        ).then(() => {
          fetchSchoolAdminsData();
          handleCloseDialog();
        });
      } else {
        dispatch(
          updateSchoolAdminData({
            id: selectedSchoolAdmin.id,
            payload: values,
            intl,
            loaderDispatch,
          }),
        ).then(() => {
          fetchSchoolAdminsData();
          handleCloseDialog();
        });
      }
    },
    validationSchema: validationSchema(intl),
  });

  const TABLE_HEAD = [
    {
      id: 'adminFirstName',
      label: intl.formatMessage({id: 'label.first_name'}),
      alignRight: false,
      dataKey: 'first_name',
    },
    {
      id: 'adminLastName',
      label: intl.formatMessage({id: 'label.last_name'}),
      alignRight: false,
      dataKey: 'last_name',
    },
    {
      id: 'adminEmail',
      label: intl.formatMessage({id: 'label.email_address'}),
      alignRight: false,
      dataKey: 'email',
    },
    {
      id: 'adminContactNumber',
      label: intl.formatMessage({id: 'label.contact_number'}),
      alignRight: false,
      dataKey: 'phone_no',
    },
    {
      id: 'actions',
      label: intl.formatMessage({id: 'label.actions'}),
      alignRight: false,
      dataKey: '',
      renderColumn: schoolAdmin => {
        return (
          <div className="d-flex actions-wrapper" ref={dropdownRef}>
            <div
              className={
                schoolAdmin?.id === selectedSchoolAdmin?.id
                  ? 'edit-delete selected-action'
                  : 'edit-delete'
              }>
              <div style={{position: 'relative'}}>
                <PiDotsThreeVertical
                  onClick={() => handleItemSelect(schoolAdmin)}
                />
                {selectedSchoolAdmin?.id === schoolAdmin?.id && (
                  <DropdownMenu isOpen={isDropdownOpen}>
                    <div
                      className="pointer"
                      onClick={() => editDetails(schoolAdmin)}>
                      {intl.formatMessage({id: 'label.view-profile'})}
                    </div>
                  </DropdownMenu>
                )}
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchSchoolAdminsData();
    fetchSchoolListData();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchSchoolAdminsData();
    }
  }, [isAuthenticated, searchTerm, currentPage]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Close dropdown when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="d-flex student-detail-wrapper">
        <div className="school-label">
          {intl.formatMessage({id: 'label.list_of_school_admins'})}
        </div>
        <div className="add-import-btn">
          <div className="import-btn">
            <button onClick={handleShowForm}>
              {intl.formatMessage({id: 'label.add_new_school_admin'})}
            </button>
          </div>
        </div>
      </div>

      <CustomTable
        tableHead={TABLE_HEAD}
        tableData={schoolAdminsList}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalPages={schoolAdminLastPage}
        totalCount={schoolAdminTotalCount}
      />

      <SchoolAdminFormDialog
        open={openDialog}
        setOpen={setOpenDialog}
        handleClose={handleCloseDialog}
        handleSubmit={handleSubmit}
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        editMode={editMode}
        schoolList={schoolList}
        selectedSchoolAdmin={selectedSchoolAdmin}
      />
    </div>
  );
}
