import React, {useState, useEffect, useContext} from 'react';
import {Image} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import HeaderName from '../../components/HeaderName';
import {useDispatch} from 'react-redux';
import {resetState} from '../../store/reducers/authReducers';
import {logout} from '../../store/actions/authActions';
import Styles from './header.style.scss';
import {useNavigate} from '../../routes';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';
// import SearchBar from './SearchBar'; // Import your SearchBar component
import {
  ALL_NOTIFICATIONS_ANNOUNCEMENTS_PATH,
  LOGIN_PATH,
  VIEW_PROFILE,
} from '../../constants/routePaths';
import {removeLocalStorageItem} from '../../services/local-storage';
import DeactivateModal from '../../components/DeactivateModal/DeactivateModal';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {getLocalStorageItem} from '../../services/local-storage';
import {setSelectedSchool} from '../../store/reducers/schoolAdminsReducers';
import {fetchAssignedSchools} from '../../store/actions/schoolAdminsActions';
import SelectDropdown from '../../components/SelectDropdown/SelectDropdown';
import UserRoles from '../../constants/userRoles';
import useUserRole from '../../hooks/useUserRole';

function HeaderContainer({headerLabel}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const userRole = useUserRole();

  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const icons = useTheme('icons');
  const {user} = icons;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openDisableModal, setOpenDisableModal] = useState(false);

  const assignedSchools = useSelector(
    state => state?.schoolAdmins?.assignedSchools,
  );

  const userEmail = useSelector(
    state => state?.auth?.data?.data?.data?.user?.email,
  );

  const userProfileData = getLocalStorageItem('myProfile');

  let Username = userProfileData.first_name + ' ' + userProfileData.last_name;
  let userProfile = userProfileData?.myImage;

  const handleLogout = () => {
    setOpenDisableModal(true);
  };
  const handleViewProfile = () => {
    navigate(VIEW_PROFILE);
  };

  const handleConfirmLogout = () => {
    try {
      dispatch(logout());
      dispatch(resetState());
      removeLocalStorageItem('userRole');
      navigate(LOGIN_PATH);
      setOpenDisableModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAvatarClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelectSchool = selectedSchool => {
    console.log(selectedSchool, 'selectedSchool');
    dispatch(setSelectedSchool(selectedSchool));
  };

  const viewAllHandler = () => {
    navigate(ALL_NOTIFICATIONS_ANNOUNCEMENTS_PATH);
  };

  const fetchAssignedSchoolsData = async () => {
    await dispatch(fetchAssignedSchools({loaderDispatch}));
  };

  const handleSetSelectedSchool = selectedSchool => {
    dispatch(setSelectedSchool(selectedSchool));
  };

  useEffect(() => {
    console.log('test');
    fetchAssignedSchoolsData();
  }, []);

  useEffect(() => {
    console.log('assignedSchools', assignedSchools);
    if (assignedSchools) {
      handleSetSelectedSchool(assignedSchools[0]?.id);
    }
  }, [assignedSchools]);

  return (
    <div className="header-container">
      <HeaderName className="left-header" headerLabel={headerLabel} />

      <div className="right-header">
        {/* <div onClick={() => setIsSearchExpanded(!isSearchExpanded)}>
            <SearchBar expanded={isSearchExpanded} />
          </div> */}

        {userRole === UserRoles.SCHOOL_ADMIN && (
          <div className="me-3">
            <SelectDropdown
              options={assignedSchools}
              onChange={e => handleSelectSchool(e.target.value)}
            />
          </div>
        )}

        <div className="header-icon">
          <img
            src="/images/notification.svg"
            alt="notification"
            style={{height: '20px', width: '20px'}}
            onClick={viewAllHandler}
          />
        </div>

        <div style={{position: 'relative'}} className="user-profile-image">
          <Image
            source={userProfile ? userProfile : user}
            onClick={handleAvatarClick}
            style={{width: '40px', height: '40px', cursor: 'pointer'}}
          />

          {isDropdownOpen && (
            <DropdownMenu className="profile_dropdown">
              <div className="pointer">
                <div>{Username}</div>
                <div>{userEmail}</div>
              </div>
              <div className="pointer" onClick={handleViewProfile}>
                {intl.formatMessage({id: 'label.view_profile'})}
              </div>
              <div className="pointer" onClick={handleLogout}>
                {intl.formatMessage({id: 'label.logout'})}
              </div>
            </DropdownMenu>
          )}

          <DeactivateModal
            open={openDisableModal}
            setOpen={setOpenDisableModal}
            handleDeactivate={handleConfirmLogout}
            confirmText={intl.formatMessage({id: 'label.logout'})}
            modalContentText={intl.formatMessage({id: 'label.sure_to_logout'})}
          />
        </div>
      </div>
    </div>
  );
}

export default HeaderContainer;
