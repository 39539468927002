import React, {useEffect, useState} from 'react';
import CustomDialogue from '../../components/CustomDialogue/CustomDialogue';
import InputLabel from '../../components/InputLabel/InputLabel';
import {useIntl} from 'react-intl';
import {addSchoolAdminFieldName} from '../../validationSchema/SchoolAdminSchema.js';
import Select from 'react-select';

const SchoolAdminFormDialog = ({
  open,
  handleClose,
  handleSubmit,
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  editMode,
  schoolList,
  selectedSchoolAdmin,
}) => {
  const intl = useIntl();

  const [selectedOptions, setSelectedOptions] = useState([]);

  const listOfSchools = schoolList?.map(school => ({
    value: school.id,
    label: school.name,
  }));

  const handleSelect = options => {
    setSelectedOptions(options);
    values[addSchoolAdminFieldName.SCHOOL_IDS] = options.map(
      option => option.value,
    );
  };

  useEffect(() => {
    if (selectedSchoolAdmin && editMode) {
      setFieldValue(
        addSchoolAdminFieldName.FIRST_NAME,
        selectedSchoolAdmin.first_name || '',
      );
      setFieldValue(
        addSchoolAdminFieldName.LAST_NAME,
        selectedSchoolAdmin.last_name || '',
      );
      setFieldValue(
        addSchoolAdminFieldName.EMAIL_ADDRESS,
        selectedSchoolAdmin.email || '',
      );
      setFieldValue(
        addSchoolAdminFieldName.PHONE_NO,
        selectedSchoolAdmin.phone_no || '',
      );

      // Map adminSchool based on schoolList
      const mappedSchoolOptions =
        selectedSchoolAdmin.adminschool
          ?.map(item => {
            const matchedSchool = schoolList?.find(
              school => school.id === item.school_id,
            );
            return matchedSchool
              ? {value: matchedSchool.id, label: matchedSchool.name}
              : null;
          })
          .filter(option => option !== null) || [];

      handleSelect(mappedSchoolOptions);

      values[addSchoolAdminFieldName.SCHOOL_IDS] = mappedSchoolOptions.map(
        option => option.value,
      );

      setFieldValue(
        addSchoolAdminFieldName.SCHOOL_IDS,
        values[addSchoolAdminFieldName.SCHOOL_IDS] || [],
      );

      setFieldValue(addSchoolAdminFieldName.SCHOOL_IDS, mappedSchoolOptions);
    } else {
      setFieldValue(addSchoolAdminFieldName.FIRST_NAME, '');
      setFieldValue(addSchoolAdminFieldName.LAST_NAME, '');
      setFieldValue(addSchoolAdminFieldName.EMAIL_ADDRESS, '');
      setFieldValue(addSchoolAdminFieldName.PHONE_NO, '');
      setFieldValue(addSchoolAdminFieldName.SCHOOL_IDS, []);
      setSelectedOptions([]);
    }
  }, [selectedSchoolAdmin, setFieldValue, editMode]);

  return (
    <CustomDialogue
      open={open}
      handleClose={handleClose}
      cancelText={intl.formatMessage({
        id: 'label.cancel',
      })}
      confirmText={intl.formatMessage({
        id: 'label.save',
      })}
      onCancel={handleClose}
      hideTitle={false}
      onSubmit={handleSubmit}>
      <div className="container">
        <div className="row mt-4">
          <div className="mb-3 col-md-12">
            <InputLabel
              name={addSchoolAdminFieldName.FIRST_NAME}
              label={intl.formatMessage({
                id: 'label.first_name',
              })}
              placeholder={intl.formatMessage({
                id: 'label.enter_first_name',
              })}
              required
              value={values[addSchoolAdminFieldName.FIRST_NAME]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addSchoolAdminFieldName.FIRST_NAME] &&
                errors[addSchoolAdminFieldName.FIRST_NAME]
              }
            />
          </div>
          <div className="mb-3 col-md-12">
            <InputLabel
              name={addSchoolAdminFieldName.LAST_NAME}
              label={intl.formatMessage({
                id: 'label.last_name',
              })}
              placeholder={intl.formatMessage({
                id: 'label.enter_last_name',
              })}
              required
              value={values[addSchoolAdminFieldName.LAST_NAME]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addSchoolAdminFieldName.LAST_NAME] &&
                errors[addSchoolAdminFieldName.LAST_NAME]
              }
            />
          </div>
          <div className="mb-3 col-md-12">
            <InputLabel
              name={addSchoolAdminFieldName.EMAIL_ADDRESS}
              label={intl.formatMessage({
                id: 'label.email_address',
              })}
              placeholder={intl.formatMessage({
                id: 'label.enter_email_address',
              })}
              required
              value={values[addSchoolAdminFieldName.EMAIL_ADDRESS]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addSchoolAdminFieldName.EMAIL_ADDRESS] &&
                errors[addSchoolAdminFieldName.EMAIL_ADDRESS]
              }
              disabled={editMode}
            />
          </div>
          <div className="mb-3 col-md-12">
            <InputLabel
              name={addSchoolAdminFieldName.PHONE_NO}
              label={intl.formatMessage({
                id: 'label.contact_number',
              })}
              placeholder={intl.formatMessage({
                id: 'label.enter_contact_number',
              })}
              required
              value={values[addSchoolAdminFieldName.PHONE_NO]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addSchoolAdminFieldName.PHONE_NO] &&
                errors[addSchoolAdminFieldName.PHONE_NO]
              }
            />
          </div>
          <div className="mb-3 col-md-12">
            <label className="form-label cus-form-label">
              <span>{intl.formatMessage({id: 'label.school_id'})}</span>{' '}
              <span className="text-danger">*</span>
            </label>
            <Select
              isMulti
              name={addSchoolAdminFieldName.SCHOOL_IDS}
              options={listOfSchools}
              className="basic-multi-select"
              classNamePrefix="select"
              value={selectedOptions}
              onChange={handleSelect}
            />
            {errors[addSchoolAdminFieldName.SCHOOL_IDS] &&
              touched[addSchoolAdminFieldName.SCHOOL_IDS] && (
                <div className="text-danger">
                  {errors[addSchoolAdminFieldName.SCHOOL_IDS]}
                </div>
              )}
          </div>
        </div>
      </div>
    </CustomDialogue>
  );
};

export default SchoolAdminFormDialog;
