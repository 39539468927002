import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {showToast} from '../../components/Toast/Toast';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';
import {MANAGE_SCHOOL_ADMINS_PATH} from '../../constants/routePaths';

export const createSchoolAdmin = createAsyncThunk(
  'schoolAdmins/createSchoolAdmin',
  async ({payload, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.post(
        '/store-school-admin',
        payload,
      );
      loaderDispatch(setLoading(false));
      showToast(
        getLocalizedMessage(intl, 'success.school_admin_added'),
        'success',
      );
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const fetchSchoolAdmins = createAsyncThunk(
  'staff/fetchSchoolAdmins',
  async ({currentPage, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const params = {
        page: currentPage,
      };

      const response = await axiosPrivateInstance.get('/school-admins', {
        params,
      });

      loaderDispatch(setLoading(false));

      return response.data.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);

export const fetchSchoolList = createAsyncThunk(
  'staff/fetchSchoolList',
  async () => {
    try {
      const response = await axiosPrivateInstance.get('/list-all-schools');

      return response.data.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);

export const updateSchoolAdminData = createAsyncThunk(
  'schoolAdmins/updateSchoolAdminData',
  async ({id, payload, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));

    const schoolAdminData = {
      ...payload,
      school_ids: payload.school_ids.map(id => {
        if (id?.value) {
          return id.value;
        } else {
          return id;
        }
      }),
    };

    try {
      const response = await axiosPrivateInstance.put(
        `/update-school-admin/${id}`,
        schoolAdminData,
      );
      loaderDispatch(setLoading(false));
      showToast(
        getLocalizedMessage(intl, 'success.school_admin_updated'),
        'success',
      );
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const fetchAssignedSchools = createAsyncThunk(
  'schoolAdmins/fetchAssignedSchools',
  async ({loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.get(`/list-my-schools`);
      loaderDispatch(setLoading(false));
      return response.data.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);
