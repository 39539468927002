import {createSlice} from '@reduxjs/toolkit';
import {
  fetchSchoolList,
  fetchSchoolAdmins,
  fetchAssignedSchools,
} from '../actions/schoolAdminsActions';

const initialState = {
  schoolAdmins: null,
  listOfSchools: null,
  selectedSchool: null,
  assignedSchools: null,
};

const schoolAdminsSlice = createSlice({
  name: 'schoolAdmins',
  initialState,
  reducers: {
    setSelectedSchool: (state, action) => {
      state.selectedSchool = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSchoolAdmins.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSchoolAdmins.fulfilled, (state, action) => {
        state.loading = false;
        state.schoolAdmins = action.payload;
        state.error = null;
      })
      .addCase(fetchSchoolAdmins.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSchoolList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSchoolList.fulfilled, (state, action) => {
        state.loading = false;
        state.listOfSchools = action.payload;
        state.error = null;
      })
      .addCase(fetchSchoolList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAssignedSchools.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAssignedSchools.fulfilled, (state, action) => {
        state.loading = false;
        state.assignedSchools = action.payload;
        state.error = null;
      })
      .addCase(fetchAssignedSchools.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {setSelectedSchool} = schoolAdminsSlice.actions;
export default schoolAdminsSlice.reducer;
