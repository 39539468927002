import React, {useEffect, useState, useContext} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import './CustomTable.scss';
import TableNotFound from './TableNotFound';
import {sortLessonContents} from '../../store/actions/lessonActions';
import {sortStaticContents} from '../../store/actions/helpActions';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

function CustomTable(props) {
  const {
    tableHead,
    tableData,
    totalCount,
    selected = [],
    setSelected = () => {},
    handleClick = () => {},
    showSelected = true,
    pageSize,
    currentPage,
    onPageChange,
    totalPages,
    showPagination = true,
    isDashboardTable = false,
    dashboardTableClass,
    sortable = false,
    sectionId,
  } = props;

  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const intl = useIntl();
  const [rowData, setRowData] = useState([]);
  const [draggingRowIndex, setDraggingRowIndex] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setRowData(tableData);
  }, [tableData]);

  const handleDragStart = index => {
    setDraggingRowIndex(index);
  };

  const handleDragOver = event => {
    event.preventDefault();
  };

  const handleDrop = index => {
    const draggedRow = rowData[draggingRowIndex];
    const newRowData = [...rowData];
    newRowData.splice(draggingRowIndex, 1);
    newRowData.splice(index, 0, draggedRow);
    setRowData(newRowData);
    setDraggingRowIndex(null);

    // Generate the payload
    const lessonIds = newRowData.map(row => row.id);
    let payload = {
      lesson_ids: lessonIds,
      section_id: sectionId,
    };

    if (!sectionId) {
      payload = {
        static_content_ids: [...lessonIds],
        page_name: 'help',
      };

      dispatch(
        sortStaticContents({
          payload,
          intl,
          loaderDispatch,
        }),
      );
    } else {
      dispatch(
        sortLessonContents({
          payload,
          intl,
          loaderDispatch,
        }),
      );
    }
  };

  const renderTable = () => {
    return rowData?.length > 0 ? (
      <TableBody>
        {rowData?.map((row, index) => {
          return (
            <TableRow
              hover
              key={row?.id || row?.email}
              tabIndex={-1}
              draggable={sortable}
              onDragStart={() => sortable && handleDragStart(index)}
              onDragOver={e => sortable && handleDragOver(e)}
              onDrop={() => sortable && handleDrop(index)}
              style={{
                opacity: draggingRowIndex === index ? 0.5 : 1, // Add visual feedback during drag
              }}>
              {sortable && (
                <TableCell
                  align="center"
                  style={{cursor: 'grab', width: '40px'}}>
                  <IconButton>
                    <span className="handle-icon mr-8">☰</span>{' '}
                  </IconButton>
                </TableCell>
              )}
              {tableHead?.map(column => {
                return (
                  <TableCell
                    key={`${row?.id}${column?.id}`}
                    align={column.textAlign || 'left'}
                    sx={{
                      ...(column?.minWidth
                        ? {minWidth: column?.minWidth}
                        : {minWidth: '10%'}),
                      ...(column?.ellipsis && {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }),
                    }}>
                    {column.renderColumn ? (
                      column.renderColumn(row, index)
                    ) : (
                      <Typography variant="subtitle" noWrap>
                        {row[column?.dataKey]}
                      </Typography>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    ) : (
      <TableNotFound />
    );
  };

  const goToPage = page => {
    if (page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <>
      <Table
        stickyHeader
        className={`custom-table ${
          isDashboardTable ? dashboardTableClass : ''
        }`}>
        <TableHead className="custom-table-header">
          <TableRow>
            {sortable && <TableCell align="center" width="40px"></TableCell>}
            {tableHead.map(headCell => (
              <TableCell
                className={headCell.id === 'actions' ? 'text-center' : ''}
                key={headCell.id}
                width={headCell.width}
                align="center">
                <TableSortLabel hideSortIcon>{headCell.label}</TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {renderTable()}
      </Table>

      {showPagination && totalPages && (
        <div className="pagination-wrapper">
          <div>
            <span>{`Page ${currentPage} of ${totalPages}`}</span>
          </div>
          <div className="pagination-btn">
            <button
              disabled={currentPage === 1}
              onClick={() => goToPage(currentPage - 1)}>
              Previous
            </button>

            <button
              disabled={currentPage === totalPages}
              onClick={() => goToPage(currentPage + 1)}>
              Next
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default CustomTable;
