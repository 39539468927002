export const SCHOOL_TEMPLATE_TYPE = 'school';
export const STUDENT_TEMPATE_TYPE = 'student';
export const STAFF_TEMPLATE_TYPE = 'staff';
export const MAX_FILE_SIZE = 512 * 1024 * 1024; // 512 MB in bytes

export const COLOR_ITEMS = [
  {borderColor: '#374151', bgColor: '#F9FAFB', borderWidth: '1px'},
  {borderColor: '#1CB0F6', bgColor: '#F0FAFE', borderWidth: '1px'},
  {borderColor: '#CE82FF', bgColor: '#FBF5FF', borderWidth: '1px'},
  {borderColor: '#FFD600', bgColor: '#FFFCEB', borderWidth: '1px'},

  {borderColor: '#29FF3E', bgColor: '#EBFFED', borderWidth: '1px'},
  {borderColor: '#4B7BFF', bgColor: '#EBF0FF', borderWidth: '1px'},
  {borderColor: '#002BC2', bgColor: '#EBEFFF', borderWidth: '1px'},
  {borderColor: '#FCA84C', bgColor: '#FFFAF5', borderWidth: '1px'},

  {borderColor: '#02AE85', bgColor: '#F5FFFD', borderWidth: '1px'},
  {borderColor: '#0088C2', bgColor: '#EBF9FF', borderWidth: '1px'},
  {borderColor: '#8400C2', bgColor: '#F8EBFF', borderWidth: '1px'},
  {borderColor: '#C92B2B', bgColor: '#FBEEEE', borderWidth: '1px'},
];

export const MANAGE_STUDENTS = 'Manage Students';
export const MANAGE_SCHOOLS = 'Manage Schools';
export const MANAGE_STAFF = 'Manage Staff';
export const REPORTS = 'Reports';
export const PILOT_REPORTING = 'Pilot Reporting';
export const ISLANDS_CONTENT = 'Islands Content';
export const SYSTEM_SETTINGS = 'System Settings';
export const MANAGE_QUESTIONS = 'Manage Questions';
export const DASHBOARD = 'Dashboard';
export const MY_PROFILE = 'My Profile';
export const ANNOUNCEMENTS = 'Announcements';
export const NOTIFY_ANNOUNCEMENTS = 'Notifications & Announcements';
export const SUPER_ADMIN_MGMT = 'Super Admin Management';
export const STAFF_STUDENT_ATTACHMENTS = 'Student Attachments';
export const MANAGE_SCHOOL_ADMINS = 'Manage School Admins';

export const LESSON_CONTENT_OBJECT_TYPE = {
  ALERT: 'alert',
  NOTE: 'note',
  REMEMBER: 'remember',
  BEST_ADVICE: 'best_advice',
  ARTICLE: 'article',
  DOCUMENT: 'document',
  TEXT: 'text',
  IMAGE_VIDEO: 'image/video',
};

export const VOICE_OPTIONS = [
  {
    id: 'Andrew EN',
    name: 'Andy',
  },
  {
    id: 'Nancy',
    name: 'Sam',
  },
  {
    id: 'Amy plus',
    name: 'Alex',
  },
];
