import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchStudentList,
  fetchGradeList,
  fetchSectionList,
  updateStudentStatus,
  resetPasswordStudent,
} from '../../store/actions/studentActions.js';
import {fetchSchoolById} from '../../store/actions/schoolActions';
import CustomTable from '../../components/CustomTable/CustomTable.js';

import SearchFilterSection from '../../components/SearchFilterSection/SearchFilterSection.js';
import {PiDotsThreeVertical} from 'react-icons/pi';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu.js';
import {useNavigate} from '../../routes/index.js';
import {
  ADD_STUDENT_PATH,
  UPDATE_STUDENT_PATH,
} from '../../constants/routePaths.js';
import {useIntl} from 'react-intl';
import {STUDENT_TEMPATE_TYPE} from '../../utilityFunction/constants.js';
import {
  uploadTemplate,
  downloadTemplate,
} from '../../store/actions/bulkImportActions';
import ImportBulkModal from '../../components/ImportBulkModal/ImportBulkModal.js';
import DeactivateModal from '../../components/DeactivateModal/DeactivateModal.js';

import CustomDialogue from '../../components/CustomDialogue/CustomDialogue';
import InputLabel from '../../components/InputLabel/InputLabel';
import {
  resetPasswordFieldName,
  resetPasswordInitialValues,
  validationSchema,
} from '../../validationSchema/resetPasswordStudentSchema';
import {useFormik} from 'formik';
import {
  checkIsSuperAdmin,
  getLocalizedMessage,
  getStatusText,
  isTeachers,
} from '../../utilityFunction/helper.js';
import useUserRole from '../../hooks/useUserRole.js';
import UserRoles from '../../constants/userRoles.js';
import {
  fetchSchoolList,
  getDistricts,
} from '../../store/actions/schoolActions.js';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider.js';

function StudentListing() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useUserRole();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const studentList = useSelector(state => state.student.studentList?.data);
  const gradeList = useSelector(state => state.student.gradeList?.data?.data);
  const sectionList = useSelector(state => state.student.sectionList?.data);
  const schoolList = useSelector(
    state => state?.school?.schoolList?.data?.data?.data,
  );
  const DistrictsList = useSelector(state => state?.school?.districtData);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [schoolId, setSchoolId] = useState(null);
  const [districtId, setDistrictId] = useState(null);
  const [schoolData, setSchoolData] = useState(null);
  const isSuperAdminRole = checkIsSuperAdmin(userRole);
  const isTeacherRole = isTeachers(userRole);

  const userSchoolId = useSelector(
    state => state?.schoolAdmins?.selectedSchool,
  );

  useEffect(() => {
    dispatch(fetchGradeList());
    dispatch(fetchSectionList());
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchStudentData();
    }
  }, [
    isAuthenticated,
    searchTerm,
    selectedSection,
    selectedGrade,
    currentPage,
    sortOrder,
    schoolId,
    districtId,
    userSchoolId,
  ]);

  useEffect(() => {
    if (isAuthenticated && showSchoolDropdown()) {
      dispatch(fetchSchoolList({loaderDispatch}));
      dispatch(getDistricts({}));
    }
  }, [isAuthenticated]);

  const showSchoolDropdown = () => {
    const rolePermissions = [UserRoles.SUPER_ADMIN];
    return rolePermissions?.includes(userRole);
  };

  const getDistrictList = () => {
    return DistrictsList?.map(opt => ({
      ...opt,
      id: opt?.district_id,
      name: opt?.district_name,
    }));
  };

  const showAddImportBtns = () => {
    const rolePermissions = [UserRoles.SCHOOL_ADMIN, UserRoles.COUNSELLOR];
    return rolePermissions?.includes(userRole);
  };

  const fetchStudentData = async () => {
    try {
      await dispatch(
        fetchStudentList({
          searchTerm,
          sectionId: selectedSection,
          gradeId: selectedGrade,
          currentPage,
          sortOrder,
          schoolId: userSchoolId,
          districtId,
          loaderDispatch,
        }),
      );
    } catch (error) {
      console.log('Error fetching school data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleGradeChange = event => {
    setSelectedGrade(event.target.value);
  };

  const handleSectionChange = event => {
    setSelectedSection(event.target.value);
  };

  const handleSort = () => {
    if (loading) return;
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';

    setLoading(true);
    setSortOrder(newSortOrder);
    fetchStudentData(newSortOrder);
  };

  const handleViewAll = () => {
    setSelectedGrade('');
    setSelectedSection('');
    setCurrentPage(1);
    fetchStudentData();
  };

  const handleAvatarClick = student => {
    if (selectedStudent?.id === student?.id) setSelectedStudent(null);
    else setSelectedStudent(student);
  };

  const viewProfile = id => {
    navigate(`${UPDATE_STUDENT_PATH.replace(':id', id)}`);
  };

  const handleDeactivateConfirm = async () => {
    if (selectedStudent?.id) {
      setOpenDeactivateModal(false);
      dispatch(updateStudentStatus({id: selectedStudent?.id, intl})).then(
        () => {
          setSelectedStudent(null);
          fetchStudentData();
        },
      );
    }
  };

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handleAddStudent = () => {
    if (studentList.total <= schoolData.student_limit) {
      navigate(ADD_STUDENT_PATH);
    }
  };

  const handleSchoolChange = value => {
    setSchoolId(value);
  };
  const handleDistrictChange = value => {
    setDistrictId(value);
  };

  const TABLE_HEAD = [
    {
      id: 'studentName',
      label: intl.formatMessage({id: 'label.student_name'}),
      alignRight: false,
      dataKey: '',
      renderColumn: student => {
        return (
          <div key={student.id} className="s_name_address d-flex">
            <div className="s_logo">
              <img
                src={
                  student.media
                    ? student.media?.path
                    : '/images/studentPlaceholder.png'
                }
                alt="School Logo"
              />
            </div>
            <div>
              <div className="s_name">{student.first_name}</div>
              <div className="s_address">{student.address || '-'}</div>
            </div>
          </div>
        );
      },
    },
    {
      id: 'studentEmailAddress',
      label: intl.formatMessage({id: 'label.student_email_address'}),
      alignRight: false,
      dataKey: 'email',
    },
    {
      id: 'assignedCounsellor',
      label: intl.formatMessage({id: 'label.assigned_counsellor'}),
      alignRight: false,
      dataKey: 'grade_name',
      renderColumn: student => {
        return (
          <div key={student.id} className="s_name_address d-flex">
            <div>
              <div className="s_name">{student.counsellor.first_name}</div>
              <div className="s_address">{student.grade_name}</div>
            </div>
          </div>
        );
      },
    },
    {
      id: 'assignedTeacher',
      label: intl.formatMessage({id: 'label.assigned_teacher'}),
      alignRight: false,
      dataKey: '',
      renderColumn: student => {
        return (
          <div key={student.id} className="s_name_address d-flex">
            <span className="s_name">{student?.teacher?.first_name}</span>
          </div>
        );
      },
    },
    userRole === UserRoles.SUPER_ADMIN && {
      id: 'schoolName',
      label: intl.formatMessage({id: 'label.assigned_school'}),
      alignRight: false,
      dataKey: '',
      renderColumn: student => {
        return (
          <div key={student.id} className="s_name_address d-flex">
            <span className="s_name">
              {student?.school ? student.school.name : 'N/A'}
            </span>
          </div>
        );
      },
    },

    {
      id: 'tokenEarned',
      label: intl.formatMessage({id: 'label.token_earned'}),
      alignRight: false,
      dataKey: 'earned_tokens',
      renderColumn: student => {
        return (
          <div className="s_name_address d-flex align-items-center">
            <img src="/images/tokens.svg" alt="tokens" />
            <span className="s_name ps-1">{student?.earned_tokens}</span>
          </div>
        );
      },
    },
    {
      id: 'quickActions',
      label: intl.formatMessage({id: 'label.quick_actions'}),
      alignRight: false,
      dataKey: '',
      renderColumn: student => {
        return (
          <div className="d-flex actions-wrapper">
            {/* <div className="actions gap-4">
              <img src="/images/message-text.png" alt="message-chat" />
              <img src="/images/send.svg" alt="send" />
            </div> */}
            <div
              className={
                student?.id === selectedStudent?.id
                  ? 'edit-delete selected-action'
                  : 'edit-delete'
              }>
              <div style={{position: 'relative'}}>
                <PiDotsThreeVertical
                  onClick={() => handleAvatarClick(student)}
                />
                {student?.id === selectedStudent?.id && (
                  <DropdownMenu isOpen={true}>
                    <div
                      className="pointer"
                      onClick={() => viewProfile(student.id)}>
                      {intl.formatMessage({id: 'label.view-profile'})}
                    </div>

                    {!isSuperAdminRole && !isTeacherRole && (
                      <div
                        className="pointer"
                        onClick={() => handleResetPassword(student.id)}>
                        {intl.formatMessage({id: 'label.reset-password'})}
                      </div>
                    )}

                    {!isSuperAdminRole && (
                      <div
                        className="pointer"
                        onClick={() => setOpenDeactivateModal(true)}>
                        {getStatusText(student?.is_active, intl)}
                      </div>
                    )}
                  </DropdownMenu>
                )}
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const templateDownloadHandler = async () => {
    dispatch(
      downloadTemplate({
        templateType: STUDENT_TEMPATE_TYPE,
        intl,
        templateName: 'Student_Template.xlsx',
        loaderDispatch,
      }),
    );
    setShowImportModal(false);
  };

  const fileImportHandler = async file => {
    dispatch(uploadTemplate({file, url: '/bulk-upload-student', intl}))
      .then(() => {
        fetchStudentData();
        setShowImportModal(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleResetPassword = studentId => {
    setSelectedStudentId(studentId);
    setFieldValue(resetPasswordFieldName.PASSWORD, '');
    setFieldValue(resetPasswordFieldName.CONFIRM_PASSWORD, '');
    setOpenResetPasswordDialog(true);
  };

  const handleCloseResetPasswordDialog = () => {
    setOpenResetPasswordDialog(false);
    setSelectedStudentId(null);
  };

  const handleShowImportModal = () => {
    if (schoolData?.student_limit === studentList?.total) {
      return;
    }
    setShowImportModal(true);
  };

  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: resetPasswordInitialValues,
    onSubmit: values => {
      const newPassword = values[resetPasswordFieldName.PASSWORD];
      dispatch(
        resetPasswordStudent({
          student_id: selectedStudentId,
          password: newPassword,
        }),
      );
      handleCloseResetPasswordDialog();

      setOpen(false);
    },
    validationSchema: validationSchema(intl),
  });

  useEffect(() => {
    const rolePermissions = [UserRoles.SCHOOL_ADMIN, UserRoles.COUNSELLOR];
    if (rolePermissions?.includes(userRole)) {
      setLoading(true);
      const schoolId = studentList?.data[0]?.school.id;
      setSchoolId(schoolId);
    }
  }, [studentList]);

  useEffect(() => {
    const rolePermissions = [UserRoles.SCHOOL_ADMIN, UserRoles.COUNSELLOR];
    if (rolePermissions?.includes(userRole) && schoolId) {
      dispatch(fetchSchoolById(schoolId))
        .then(response => {
          const schoolData = response.payload.data;
          setSchoolData(schoolData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [schoolId]);

  return (
    <div>
      <div className="d-flex student-detail-wrapper">
        <div className="school-label">
          {intl.formatMessage({id: 'label.details_of_students'})}
        </div>
        {showAddImportBtns() && (
          <div className="add-import-btn">
            <div className="add-student-btn">
              <button
                disabled={studentList?.total >= schoolData?.student_limit}
                onClick={handleAddStudent}>
                {' '}
                {intl.formatMessage({id: 'label.add_student'})}
              </button>
            </div>
            <div className="import-btn">
              <button
                disabled={studentList?.total >= schoolData?.student_limit}
                onClick={handleShowImportModal}
                className="btn btn-primary pe-auto">
                {' '}
                <img className="me-1" src="/images/importIcon.svg" />{' '}
                {intl.formatMessage({id: 'label.import'})}
              </button>
            </div>
          </div>
        )}
      </div>

      <SearchFilterSection
        optionsOne={gradeList}
        valueOne={selectedGrade}
        handleOptionOneChange={handleGradeChange}
        optionsTwo={sectionList}
        valueTwo={selectedSection}
        handleOptionTwoChange={handleSectionChange}
        setSearchTerm={setSearchTerm}
        setCurrentPage={setCurrentPage}
        handleSort={handleSort}
        sortOrder={sortOrder}
        handleViewAll={handleViewAll}
        onSearchChange={e => {
          setSearchTerm(e.target.value);
          setCurrentPage(1);
        }}
        filterOnePlaceholder={intl.formatMessage({id: 'label.selectGrade'})}
        filterTwoPlaceholder={intl.formatMessage({id: 'label.selectGroup'})}
        searchPlaceholder={intl.formatMessage({id: 'label.search_name_class'})}
        showSelect={showSchoolDropdown()}
        dropdownOptions={schoolList}
        dropdownPlaceholder={intl.formatMessage({
          id: 'label.select.school',
        })}
        handleSelectChange={handleSchoolChange}
        dropdownOptionsTwo={getDistrictList()}
        dropdownPlaceholderTwo={intl.formatMessage({
          id: 'label.selectdistricts',
        })}
        handleSelectChangeTwo={handleDistrictChange}
      />

      <CustomTable
        tableHead={TABLE_HEAD}
        tableData={studentList?.data}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        totalPages={studentList?.last_page}
        totalCount={studentList?.total}
      />

      {showImportModal && (
        <ImportBulkModal
          open={showImportModal}
          handleClose={() => setShowImportModal(false)}
          modalTitle={'Student Bulk Upload'}
          onDownloadTemplate={templateDownloadHandler}
          onImportFile={fileImportHandler}
        />
      )}

      {openDeactivateModal && (
        <DeactivateModal
          open={openDeactivateModal}
          setOpen={setOpenDeactivateModal}
          handleDeactivate={handleDeactivateConfirm}
          confirmText={getStatusText(selectedStudent?.is_active, intl)}
          modalContentText={
            !selectedStudent?.is_active
              ? getLocalizedMessage(intl, 'label.activate-student-profile')
              : getLocalizedMessage(intl, 'label.deactivate-student-profile')
          }
        />
      )}

      {openResetPasswordDialog && (
        <CustomDialogue
          open={openResetPasswordDialog}
          handleClose={handleCloseResetPasswordDialog}
          cancelText={intl.formatMessage({
            id: 'label.cancel',
          })}
          confirmText={intl.formatMessage({
            id: 'label.reset-password',
          })}
          dialogueTitle={intl.formatMessage({
            id: 'label.reset-password',
          })}
          onCancel={handleCloseResetPasswordDialog}
          hideTitle={false}
          onSubmit={handleSubmit}>
          <div className="container">
            <div className="row">
              <div className="mb-3 col-md-6">
                <InputLabel
                  name={resetPasswordFieldName.PASSWORD}
                  label={intl.formatMessage({
                    id: 'label.password',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'label.re-new-password',
                  })}
                  required
                  value={values[resetPasswordFieldName.PASSWORD]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched[resetPasswordFieldName.PASSWORD] &&
                    errors[resetPasswordFieldName.PASSWORD]
                  }
                />
              </div>
              <div className="mb-3 col-md-6">
                <InputLabel
                  name={resetPasswordFieldName.CONFIRM_PASSWORD}
                  label={intl.formatMessage({
                    id: 'label.confirm_password',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'label.re-enter_password',
                  })}
                  required
                  value={values[resetPasswordFieldName.CONFIRM_PASSWORD]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched[resetPasswordFieldName.CONFIRM_PASSWORD] &&
                    errors[resetPasswordFieldName.CONFIRM_PASSWORD]
                  }
                />
              </div>
            </div>
          </div>
        </CustomDialogue>
      )}
    </div>
  );
}

export default StudentListing;
