import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {PiDotsThreeVertical} from 'react-icons/pi';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import CustomTable from '../../components/CustomTable/CustomTable';
import ProgressStatusView from '../../components/ProgressStatusView/ProgressStatusView';
import DashboardStatisticsCardView from '../../components/DashboardStatisticsCardView/DashboardStatisticsCardView';
import NotificationsRightColLayout from '../../components/NotificationsRightColLayout/NotificationsRightColLayout.js';
import SearchBar from '../../components/SearchBar/SearchBar';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';
import {
  getDashboardStats,
  getDashboardStudentsList,
} from '../../store/actions/dashboardActions.js';
import {
  ALL_NOTIFICATIONS_ANNOUNCEMENTS_PATH,
  MANAGE_LESSONS_PATH,
  STUDENT_DETAILS_PATH,
  UPDATE_SCHOOL_PATH,
  VIEW_LESSON_CONTENTS,
} from '../../constants/routePaths.js';
import useUserRole from '../../hooks/useUserRole.js';
import UserRoles from '../../constants/userRoles.js';
import './SchoolAdminAndStaffDashboardView.scss';

export default function SchoolAdminAndStaffDashboardView() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useUserRole();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {dashboardStudentsList, dashboardStatsInfo} = useSelector(
    state => state?.dashboard,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStudent, setSelectedStudent] = useState(null);

  const userSchoolId = useSelector(
    state => state?.schoolAdmins?.selectedSchool,
  );

  useEffect(() => {
    fetchDashboardStudentsList();
  }, [currentPage, searchTerm, userSchoolId]);

  useEffect(() => {
    if (UserRoles.SCHOOL_ADMIN === userRole) fetchDashboardStats();
  }, [userRole, userSchoolId]);

  function fetchDashboardStudentsList() {
    dispatch(
      getDashboardStudentsList({
        loaderDispatch,
        schoolId: userSchoolId,
        currentPage,
        searchTerm,
      }),
    );
  }

  function fetchDashboardStats() {
    dispatch(getDashboardStats({loaderDispatch, schoolId: userSchoolId}));
  }

  function onPageChange(page) {
    setCurrentPage(page);
  }

  function handleMenuItemClick(student) {
    if (selectedStudent?.id === student?.id) setSelectedStudent(null);
    else setSelectedStudent(student);
  }

  function redirectHandler(notification) {
    switch (notification?.type) {
      case 'App\\Notifications\\Staff\\AddQuestionNotification':
      case 'App\\Notifications\\Staff\\AnswerAddedNotification':
        const lesson = {
          id: notification?.data?.lesson_id,
        };
        navigate(VIEW_LESSON_CONTENTS, {
          state: {lessonInfo: lesson, isStaffOrSchoolAdmin: true},
        });
        break;
      case 'App\\Notifications\\Staff\\StudentAddedNotification':
      case 'App\\Notifications\\Staff\\StudentStatusChangedNotification':
        navigate(
          `${STUDENT_DETAILS_PATH.replace(
            ':id',
            notification?.data?.student_id,
          )}`,
        );
        break;
      case 'App\\Notifications\\Staff\\AddLessonNotification':
        navigate(MANAGE_LESSONS_PATH, {
          state: {
            sectionName: notification?.data?.section_name,
            sectionId: notification?.data?.section_id,
          },
        });
        break;
      case 'App\\Notifications\\SchoolAdmin\\LicenseRenewedNotification':
      case 'App\\Notifications\\SuperAdmin\\SchoolLicenseExpiryNotification':
        navigate(
          `${UPDATE_SCHOOL_PATH.replace(':id', notification?.data?.school_id)}`,
        );
      default:
        break;
    }
  }

  function onViewAllHandler() {
    navigate(ALL_NOTIFICATIONS_ANNOUNCEMENTS_PATH);
  }

  function onViewProfile(student) {
    const idStudent = student?.id;
    navigate(`${STUDENT_DETAILS_PATH.replace(':id', idStudent)}`);
  }

  const TABLE_HEAD = [
    {
      id: 'studentsName',
      label: intl.formatMessage({id: 'label.student_name'}),
      alignRight: false,
      width: '25%',
      renderColumn: student => {
        return (
          <div key={student?.id} className="student_nameWrapper d-flex">
            <div className="s_logo">
              <img
                src={
                  student.media
                    ? student.media?.path
                    : '/images/studentPlaceholder.png'
                }
                alt="Student Logo"
              />
            </div>
            <div>
              <div className="s_name">{`${student?.first_name} ${student?.last_name}`}</div>
              <div className="s_email">{student?.email || '-'}</div>
            </div>
          </div>
        );
      },
    },
    {
      id: 'progressStatus',
      label: intl.formatMessage({id: 'label.progressStatus'}),
      alignRight: false,
      width: '37%',
      renderColumn: student => {
        return <ProgressStatusView studentData={student} />;
      },
    },
    {
      id: 'tokensEarned',
      label: intl.formatMessage({id: 'label.token_earned'}),
      alignRight: false,
      width: '31%',
      renderColumn: student => {
        return (
          <div className="s_name_address d-flex align-items-center">
            <img src="/images/tokens.svg" alt="tokens" />
            <span className="s_name ps-1">{student?.earned_tokens}</span>
          </div>
        );
      },
    },
    {
      id: 'actions',
      alignRight: true,
      width: '7%',
      renderColumn: student => {
        return (
          <div className="d-flex actions-wrapper">
            <div
              className={
                student?.id === selectedStudent?.id
                  ? 'edit-delete selected-action'
                  : 'edit-delete'
              }>
              <div style={{position: 'relative'}}>
                <PiDotsThreeVertical
                  onClick={() => handleMenuItemClick(student)}
                />
                {student?.id === selectedStudent?.id && (
                  <DropdownMenu isOpen={true}>
                    <div
                      className="pointer"
                      onClick={() => onViewProfile(student)}>
                      {intl.formatMessage({id: 'label.view-profile'})}
                    </div>
                  </DropdownMenu>
                )}
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="dashboard">
      <div className="left-column">
        {UserRoles.SCHOOL_ADMIN === userRole && (
          <div className="cards-row">
            <DashboardStatisticsCardView
              title={intl.formatMessage({id: 'label.counsellors'})}
              icon={'/images/counsellorStatsIcon.svg'}
              count={dashboardStatsInfo?.counselor_count}
            />
            <DashboardStatisticsCardView
              title={intl.formatMessage({id: 'label.teachers'})}
              icon={'/images/teacherStatsIcon.svg'}
              count={dashboardStatsInfo?.teacher_count}
            />
            <DashboardStatisticsCardView
              title={intl.formatMessage({id: 'label.students'})}
              icon={'/images/studentStatsIcon.svg'}
              count={dashboardStatsInfo?.student_count}
            />
          </div>
        )}

        <div className="studentDetails-headingWrapper">
          <div className="studentDetails-headingLabel">
            {intl.formatMessage({id: 'label.studentDetails'})}
          </div>
          {UserRoles.SCHOOL_ADMIN === userRole && (
            <SearchBar
              placeholder={intl.formatMessage({
                id: 'label.searchByName',
              })}
              onChange={e => {
                setSearchTerm(e.target.value);
                setCurrentPage(1);
              }}
            />
          )}
        </div>

        <div className="content-row">
          <CustomTable
            tableHead={TABLE_HEAD}
            tableData={dashboardStudentsList?.data}
            currentPage={currentPage}
            onPageChange={onPageChange}
            totalPages={dashboardStudentsList?.last_page}
            totalCount={dashboardStudentsList?.total}
            isDashboardTable
            dashboardTableClass="dashboard-tableContainer"
          />
        </div>
      </div>

      <div className="right-column">
        <div className="notifications-list">
          <NotificationsRightColLayout
            desc={true}
            notifyIcon={'/images/book.svg'}
            redirectHandler={redirectHandler}
            viewAll={onViewAllHandler}
            isPagination={false}
            annoucementIcon={'/images/book.svg'}
          />
        </div>
      </div>
    </div>
  );
}
