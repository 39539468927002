import * as Yup from 'yup';
import {EMAIL_REGEX} from '../core/validation';

export const addSchoolAdminFieldName = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL_ADDRESS: 'email_address',
  PHONE_NO: 'phone_no',
  SCHOOL_IDS: 'school_ids',
};

export const addSchoolAdminInitialValues = {
  [addSchoolAdminFieldName.FIRST_NAME]: '',
  [addSchoolAdminFieldName.LAST_NAME]: '',
  [addSchoolAdminFieldName.EMAIL_ADDRESS]: '',
  [addSchoolAdminFieldName.PHONE_NO]: '',
  [addSchoolAdminFieldName.SCHOOL_IDS]: [],
};

export const validationSchema = intl => {
  return Yup.object().shape({
    [addSchoolAdminFieldName.FIRST_NAME]: Yup.string().required(
      intl.formatMessage({id: 'label.first_name_required'}),
    ),
    [addSchoolAdminFieldName.LAST_NAME]: Yup.string().required(
      intl.formatMessage({id: 'label.section_name_required'}),
    ),
    [addSchoolAdminFieldName.EMAIL_ADDRESS]: Yup.string()
      .required(intl.formatMessage({id: 'label.email_required'}))
      .matches(EMAIL_REGEX, intl.formatMessage({id: 'label.fieldError.email'})),
    [addSchoolAdminFieldName.PHONE_NO]: Yup.string().required(
      intl.formatMessage({id: 'label.contact_no_required'}),
    ),
    // [addSchoolAdminFieldName.SCHOOL_IDS]: Yup.array()
    //   .of(
    //     Yup.number().required(
    //       intl.formatMessage({id: 'label.school_id_required'}),
    //     ),
    //   )
    //   .min(1, intl.formatMessage({id: 'label.school_ids_required'})),
    [addSchoolAdminFieldName.SCHOOL_IDS]: Yup.array()
      .of(
        Yup.lazy(value =>
          typeof value === 'object'
            ? Yup.object().shape({
                value: Yup.string().required(),
                label: Yup.string().required(),
              })
            : Yup.number().required(),
        ),
      )
      .min(1, intl.formatMessage({id: 'label.school_ids_required'})),
  });
};
