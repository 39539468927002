import {createSlice} from '@reduxjs/toolkit';
import {login, loginWith2FA} from '../actions/authActions';
import {studentPasswordReset} from '../actions/studentActions';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from '../../services/local-storage';

const token = getLocalStorageItem('accessToken');

const initialState = {
  loading: false,
  isAuthenticated: Boolean(token),
  token: token,
  error: null,
  data: {},
  schoolList: [],
  districtsList: [],
  intermediateUnits: [],
  uploadMedia: {},
  DesignationList: [],
  CountriesList: [],
  StatesList: [],
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetState(state) {
      state.isAuthenticated = false;
      state.token = null;
      state.error = null;
      state.data = {};
      removeLocalStorageItem('accessToken');
      removeLocalStorageItem('refreshToken');
      removeLocalStorageItem('userRole');
      removeLocalStorageItem('studentIslandAvatar');
      removeLocalStorageItem('myProfile');
      removeLocalStorageItem('earned_tokens');
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.data.data.access_token;
        state.isAuthenticated = true;
        state.data = {
          ...state.data,
          ...action.payload,
        };
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(loginWith2FA.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginWith2FA.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.data.access_token;
        state.isAuthenticated = true;
        state.data = {
          ...state.data,
          ...action.payload,
        };
        state.error = null;
      })
      .addCase(loginWith2FA.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(studentPasswordReset.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(studentPasswordReset.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action?.payload?.data?.access_token;
        state.isAuthenticated = true;
        state.error = null;
      })
      .addCase(studentPasswordReset.rejected, (state, action) => {
        state.token = null;
        state.isAuthenticated = false;
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {resetState} = authSlice.actions;
export default authSlice.reducer;
